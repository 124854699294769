import PropTypes from "prop-types"
import React from "react"
import "../styles/style.css"

const Layout = ({ children }) => {
  return (
      <main className="container p-0">
        {children}
      </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHome: PropTypes.bool,
}

export default Layout
